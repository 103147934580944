body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
Author: Orange Comet Creative 
Author URI: https://orangecomet.com/
*/

/*===========================================================================  
TABLE OF CONTENTS
-----------------------------------------------------------------------------
1.0 # Variables and Helper Classes
  ## Accessibility
  ## Color
  ## Layout
2.0 # Typography
3.0 # Content
4.0 # Components 
5.0 # Media Queries 
============================================================================= */

/*===========================================================================
  # 1.0 Variables and Helper Classes
  =========================================================================== */

/*---------------------------------------------------------------------------
  ## Accessibility
  --------------------------------------------------------------------------- */
   
/* Text meant only for screen readers. */
.screen-reader-text { position: absolute !important;
  height: 1px; width: 1px; margin: -1px; padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  word-wrap: normal !important;
  overflow: hidden;
}

.screen-reader-text:focus { display: block;
  top: 5px; left: 5px; z-index: 100000;
  height: auto; width: auto; padding: 15px 23px 14px;
  background-color: #f1f1f1; color: #21759b;
  font-size: 0.875rem; font-weight: 700; line-height: normal; text-decoration: none;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6); border-radius: 3px;
  clip: auto !important;
  clip-path: none;
}
 
/* Do not show the outline on the skip link target. */
#primary[tabindex="-1"]:focus { outline: 0; }

/* Alt text */
img { font-size: var(--font-size-small); }

/*---------------------------------------------------------------------------
  ## Colors
  --------------------------------------------------------------------------- */
  
:root {
  --color-primary: #222;

  --color-accent: #fa4616;  
  --color-accent-secondary: #593D6B; 
  --color-success: #26C73C;

  --color-white: #fff;
  --color-black: #111;

  --color-gray: ##444444;
  --color-gray-light: #efefef;
  --color-gray-dark: #2e2e2e;
  
  --color-border: #a7a7a7;
  --color-border-light: #D9D9D9;
  --color-border-dark: #8C8C8C;

  --background-light: #fcfcfc;

  --box-shadow: 0 6px 9px 0 rgba(50,54,65,0.05);
  --box-shadow-dark: 0 8px 60px 0 rgba(0,0,0,0.25), 0 12px 90px 0 rgba(0,0,0,0.25);

  --opacity-90: .9;
  --opacity-80: .8;
  --opacity-70: .7;
  --opacity-60: .6;
  --opacity-50: .5;
  --opacity-40: .4;
  --opacity-30: .3;
  --opacity-20: .2;
  --opacity-10: .1;
}

/*--------------------------------------------------------------------------
  ## Layout
  -------------------------------------------------------------------------- */

/* Size Variables */ 
:root {
  --spacer-xxs: .25em;
  --spacer-xs:  .5em;
  --spacer-sm:  1em;
  --spacer-med: 2em;
  --spacer-lg:  3em;
  --spacer-xl:  4em;
  --spacer-xxl: 5em;

  --align-sm:  600px;
  --align-med: 900px; 
  --align-lg:  1500px;
}

.align-full { width: 100%!important; max-width: 100%!important; padding: 0 !important; }
.align-center--center {  display: flex; align-items: center; justify-content: center; }

/*===========================================================================
  # 2.0 Typography
  =========================================================================== */

/*
 * .com self hosted font 

@font-face {
  font-family: 'Tungsten';
  src: url('https://orangecomet.com/wp-content/themes/orangecomet/assets/fonts/tungsten/Tungsten-Medium.woff2') format('woff2'),
       url('https://orangecomet.com/wp-content/themes/orangecomet/assets/fonts/tungsten/Tungsten-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tungsten';
  src: url('https://orangecomet.com/wp-content/themes/orangecomet/assets/fonts/tungsten/Tungsten-Bold.woff2') format('woff2'),
       url('https://orangecomet.com/wp-content/themes/orangecomet/assets/fonts/tungsten/Tungsten-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
*/

:root {
  --font-primary:   'Roboto',   sans-serif;
  --font-secondary: 'Tungsten',   sans-serif;
  --font-headlines: 'bebas-neue-pro', sans-serif;

  /* 
   * Typescale based on 1.250 - Major Third 
   * https://type-scale.com/
  */
  font-size: 16px; 
  --base-font-size: 1rem; 
  --scale: 1.250; 

  --font-size-p:   var(--base-font-size);
  --font-size-h1:  calc(3.052rem * var(--scale)); 
  --font-size-h2:  calc(2.441rem * var(--scale));
  --font-size-h3:  calc(1.953rem * var(--scale));
  --font-size-h4:  calc(1.563rem * var(--scale));
  --font-size-h5:  calc(1.25rem) * var(--scale)); 
  --font-size-h6-size:  calc(var(--base-font-size) * var(--scale));

  --font-size-small: calc(.64rem   * var(--scale));
  --font-size-medium: calc(1rem    * var(--scale));

  --font-size-button: calc(.833rem * var(--scale));
}

body { font-family: var(--font-primary); }

.oc--app h1,
.oc--app h2,
.oc--app h3,
.oc--app h4,
.oc--app h5,
.oc--app h6 {
  font-family: var(--font-headlines); font-weight: 600; color: var(--color-black);
}
.oc--app h1 { font-size: var(--font-size-h1); line-height: 1; }
.oc--app h2 { font-size: var(--font-size-h2); line-height: 1.1; }
.oc--app h3 { font-size: var(--font-size-h3); line-height: 1.2; }
.oc--app h4 { font-size: var(--font-size-h4); line-height: 1.3; }
.oc--app h5 { font-size: var(--font-size-h5); line-height: 1.4;  }
.oc--app h6 { 
  font-size: var(--h6-size); line-height: 1.5; 
}
.oc--app p,
.oc--app span.validateDOMNesting { 
  font-family: var(--font-primary); font-size: var(--font-size-medium); font-weight: 300; line-height: 1.35; letter-spacing: 0; 
}

.app-footer-wrapper p { font-size: var(--font-size-small); }
.app-footer .oc-branding,
.app-footer .oc-branding p { 
  font-family: var(--font-headlines); font-size: var(--font-size-p); line-height: 1.6;
}
.app-footer .oc-branding strong { font-family: var(--font-primary); }

/* MUI Overrides */
.oc--form-results p,
.oc--form #ignoreIndexes-helper-text { 
  font-size: var(--font-size-p); 
}
.oc--form .MuiButton-containedPrimary { font-size: var(--font-size-button); }

.oc--form-results p { font-size: var(--font-size-p); }
.oc--form-results .MuiListItemText-root span { font-weight: 500; }

/*===========================================================================
  # 3.0 Content
  =========================================================================== */

/* Shared */
.app-header a,
.app-footer a {
  display: flex;
}

/* Header */
.app-header-wrapper { display: flex;   min-height: 100px; max-width: 100%;  }
.app-header-wrapper .app-header { display: flex; align-items: center; flex-direction: column ;}
.app-header-wrapper .app-header a { margin: auto; }
.app-header-wrapper .app-header .mainnet-warning { background-color: var(--color-accent); color: var(--color-white); padding: 0.5em 1em; margin:0; width:100vw; font-weight:300; text-align: center; font-size: 1rem; }

/* Footer */
.app-footer-wrapper { background: var(--background-light); border-top: 1px solid var(--color-border-light); }
.app-footer-wrapper p { color: var(--color-white); }
.app-footer-wrapper i,
.app-footer-wrapper svg { 
  color: var(--color-white); 
}
.app-footer-wrapper .app-footer { 
  display: grid; grid-template-columns: 1fr minmax(min-content, 260px); grid-gap: 1em;
  padding-top: var(--spacer-xl); padding-bottom: var(--spacer-xl);
}
.app-footer p { color: var(--color-black); }
.app-footer .oc-info { max-width: 260px; }
.app-footer .oc-info img { width: 80px; }

.app-footer-wrapper ul.social { display: inline-flex; margin: 0; padding: 0; }
.app-footer-wrapper ul.social a { display: flex; ;}
.app-footer-wrapper ul.social a svg { fill: var(--color-white); width: .8em; height: .8em; }
.app-footer-wrapper ul.social a:hover svg { fill: var(--color-accent); }
.app-footer-wrapper ul.social li { margin: 0 0 0 1em; list-style-type: none; }

.bottom-bar-wrapper { background: var(--color-black); }
.bottom-bar-wrapper .bottom-bar { 
  display: grid; grid-template-columns: 1fr minmax(min-content, max-content); 
  padding-top: var(--spacer-med); padding-bottom: var(--spacer-med);
}
.bottom-bar p { margin: 0; padding: 0; }

/* Content */
img { max-width: 100%; }
img.oc-logo { width: 280px; }

/*===========================================================================
  # 4.0 Components 
  =========================================================================== */

/* Block -- Cover */
.block-cover { display: flex; align-items: center; justify-content: center ;
  width: 100%; min-height: 300px; 
  margin: 0 0 var(--spacer-lg); margin-left: 0; padding: 0; text-align: center;
  background-size: cover; background-repeat: no-repeat;
}
.block-cover .block-cover-container { width: var(--align-med); margin: auto; padding: var(--spacer-sm); }

.block-cover.hero-architect {
  background-image: url(images/oc-hero-architect.jpg) ;
  background-position: center center;
}
.block-cover.hero-architect .block-cover-container { display: flex; width: max-content; }
.block-cover.hero-architect .MuiAvatar-root { background-color: rgba(255,255,255, .1) }
.block-cover.hero-architect .MuiAvatar-root svg { fill: var(--color-accent); }

.block-cover h1,
.block-cover h2,
.block-cover h3,
.block-cover h4 {
  margin: 0;
}
.block-cover.dark h1,
.block-cover.dark h2,
.block-cover.dark h3,
.block-cover.dark h4 { 
  color: var(--color-black); 
}
.block-cover.light h1,
.block-cover.light h2,
.block-cover.light h3,
.block-cover.light h4 {
  color: var(--color-white);
}

/* Randomness Verfication */  
.oc--app .oc--form-wrapper { width: 100%; max-width: 100%; margin-bottom: var(--spacer-lg); padding: 0; }

.oc--form-wrapper a { display: inline-flex; color: var(--color-accent); text-decoration-color: var(--color-accent); 
  transition: all 0.2s ease-in-out;
}
.oc--form-wrapper a:hover { color: var(--color-gray); }
.oc--form-wrapper p { margin-right: 0; margin-left: 0; }

.oc--form-wrapper .oc--form { 
  max-width: 900px; margin: auto; overflow: hidden;
}
.oc--form-wrapper footer { width: var(--align-med); max-width: 100%; margin: auto; }

.oc--form { margin-top: var(--spacer-xl); margin-bottom: var(--spacer-xl); }
.oc--form .form-content { margin-bottom: var(--spacer-sm); }
.oc--form .form-content h2 { margin-top: 0; }

.oc--form-results ul.MuiList-root svg { color: var(--color-accent); }
.oc--form-results ul.MuiList-root li { padding-right: 0; padding-left: 0; }
.oc--form-results ul.MuiList-root li.MuiListItem-root .MuiAvatar-root { background-color: rgba(0,0,0, .05); }

/* 
 * Mui Overrides
 * https://mui.com/ 
*/
.MuiContainer-root.app-footer-wrapper { display: grid; grid-template-columns: 1fr minmax(min-content,max-content); max-width: 1500px; }

/* Mui -- Alert 
// variables not working with rgba 
.oc--form-results .MuiAlert-root { background-color: rgba(var(--color-accent), var(--opacity-10)); }
*/
.oc--form-results .MuiAlert-root { width: 100%; background-color: rgba(38,199,60,.1); padding: 16px 16px 16px 12px; }
.oc--form-results .MuiAlert-root svg { color: var(--color-success); }

/* Mui -- Buttons */
.oc--form .MuiButtonBase-root { min-height: 50px; border-radius: 200px!important; 
  transition: 
    border-color .45s cubic-bezier(0.25, 1, 0.33, 1), 
    background-color .45s cubic-bezier(0.25, 1, 0.33, 1), 
    box-shadow .45s cubic-bezier(0.25, 1, 0.33, 1),
    color .45s cubic-bezier(0.25, 1, 0.33, 1), 
    opacity .45s cubic-bezier(0.25, 1, 0.33, 1), 
    transform .45s cubic-bezier(0.25, 1, 0.33, 1), 
}
.oc--form .MuiButton-containedPrimary { min-height: 62px; width: max-content; padding-right: 50px; padding-left: 50px; }
.oc--form .MuiButton-containedPrimary,
.oc--form .MuiButton-containedPrimary:hover { 
  background: var(--color-accent);
}

.oc--form .MuiButton-containedPrimary:hover {
  box-shadow: 0 20px 38px rgb(0 0 0 / 16%);
  transform: translateY(-3px);
}

/* Mui -- Chip */
.oc--form .MuiChip-root { display: inline-flex; margin-right: var(--spacer-xs); border-color: var(--color-accent); color: var(--color-accent); }

/* Mui -- Input */
.oc--form #ignoreIndexes-helper-text { margin-top: var(--spacer-sm); }
.oc--form .MuiInputLabel-root.Mui-focused  { color: var(--color-accent); }
.oc--form .MuiOutlinedInput-root.Mui-focused fieldset { border-color: var(--color-accent); }

/* Mui -- List */
.oc--form ul.MuiList-root p { font-size: var(--font-size-p); }

/*===========================================================================
  # 5.0 Media Queries
  =========================================================================== */

@media screen and (max-width: 600px) {
  :root {
    --scale: 1.2; 
  }
  .app-footer-wrapper .app-footer { grid-template-columns: 1fr; }
}  